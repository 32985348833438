
import {Component, Vue} from 'vue-property-decorator';

@Component({
  name: 'floor-preload',
})
export default class FloorPreload extends Vue {
  mounted() {
    console.log(this.$route)
    if (Object.keys(this.$route.params).length > 0) {
      this.$router.push({
        name: 'floorWithParams',
        params: this.$route.params,
      });
    } else {
      this.$router.push({
        name: 'floor',
        params: this.$route.params,
      });
    }
  }
}
